<ng-container *transloco="let t">
  <div class="bg-dwhite flex h-full flex-col shadow-xl">
    <div class="scrollbar flex flex-1 flex-col overflow-y-scroll">
      <!-- Header -->
      <header class="bg-gray-50 px-4 py-6 sm:px-6">
        <div class="flex items-start justify-between space-x-3">
          <div>
            <h3 class="heading-new">
              {{ heading }}
            </h3>
            <p class="subtitle-new">
              {{ subtitle }}
            </p>
          </div>
          <div class="flex h-7 items-center">
            <button
              (click)="closePortal.emit()"
              aria-label="Close panel"
              class="text-gray-400 transition duration-150 ease-in-out hover:text-gray-500"
            >
              <!-- Heroicon name: x -->
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </header>

      <ng-content></ng-content>
    </div>

    <!-- Action buttons -->
    @if (showFooter) {
      <div class="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
        <div class="mr-20 flex justify-end gap-4">
          <span class="inline-flex rounded-md shadow-sm">
            <button
              (click)="closePortal.emit()"
              type="button"
              class="btn btn-gray--stroked"
            >
              {{ isSaveable ? t('common.cancel') : t('common.close') }}
            </button>
          </span>
          @if (isSaveable) {
            <span class="inline-flex rounded-md shadow-sm">
              <button
                (click)="save.emit()"
                [disabled]="saveDisabled"
                type="submit"
                class="btn btn-primary"
              >
                {{ t('common.save') }}
              </button>
            </span>
          }
        </div>
      </div>
    }
  </div>
</ng-container>
