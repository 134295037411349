import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'mspot-portal-form-wrapper',
  templateUrl: './portal-form-wrapper.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalFormWrapperComponent {
  @Input() heading?: string;
  @Input() subtitle?: string;

  @Input() saveDisabled: boolean | undefined | null = false;
  @Input() showFooter = true;

  @Output() closePortal = new EventEmitter();
  @Output() save = new EventEmitter();

  get isSaveable() {
    return this.save.observers.length;
  }
}
