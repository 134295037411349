import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector, Type } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { PortalSidebarComponent } from '@memberspot/frontend/shared/ui/sidebar-layout';
import { DialogBaseService } from '@memberspot/frontend/shared/util/dialog';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';

import { PORTAL_REF, PortalRef } from './portal.model';
import { PortalLoaderComponent } from './portal-loader/portal-loader.component';

export interface PortalLoadable {
  isDirty(): boolean;
}

export type PortalOpenState = 'large' | 'small';
export type PortalState = false | PortalOpenState | null;

@Injectable({
  providedIn: 'root',
})
export class PortalLoaderService {
  private showPortalSubj = new BehaviorSubject<PortalState>(false);
  private _portalComponent?: PortalLoaderComponent;
  private _returnDataSubj?: Subject<any>;

  private _currentComp?: ComponentRef<PortalLoadable>;

  showPortal$ = this.showPortalSubj.asObservable();

  constructor(
    private _injector: Injector,
    private router: Router,
    private dialogService: DialogBaseService,
  ) {
    router.events
      .pipe(
        filter((e) => e instanceof NavigationStart),
        withLatestFrom(this.showPortal$),
        filter(([, show]) => !!show),
      )
      .subscribe(() => this.destroy(null));
  }

  /**
   *
   * @param U Type of passed Dta
   * @param R Type of Return Data
   * provide Data as
   */
  open<U = any, R = any, T extends PortalLoadable = PortalLoadable>(
    componentRef: Type<T>,
    data: U,
    size: PortalOpenState = 'large',
  ): Observable<R> {
    const portalRef: PortalRef<U> = {
      close: this.destroy.bind(this),
      data,
    };

    this.router.navigate([], { queryParams: { portal: 1 } });

    const injector = Injector.create({
      parent: this._injector,
      providers: [
        {
          provide: PORTAL_REF,
          useValue: portalRef,
        },
      ],
    });

    const compPortal = new ComponentPortal(componentRef, undefined, injector);
    const comp = this._portalComponent?.attachComponentPortal(compPortal);

    comp?.changeDetectorRef.markForCheck();
    this._currentComp = comp;
    this.showPortalSubj.next(size);

    this._returnDataSubj = new Subject<R>();

    return this._returnDataSubj.pipe(filter((d) => !!d));
  }

  destroy(data: any) {
    this._returnDataSubj?.next(data);
    this.showPortalSubj.next(false);
    this._portalComponent?.dettachComponentPortal();
  }

  registerPortal(
    portalComponent: PortalLoaderComponent,
    drawer: PortalSidebarComponent,
  ) {
    this._portalComponent = portalComponent;
    drawer.backdropClick
      .pipe(
        switchMap(() =>
          this._currentComp?.instance?.isDirty()
            ? this.dialogService.unsavedChanges()
            : of(true),
        ),
      )
      .subscribe((res) => (res ? this.destroy(null) : null));
  }
}
